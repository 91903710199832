<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <div class="align-items-end justify-content-end text-right text-white">
                    <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
                </div>
                <div class="text-center">
                    <img :src="require('../assets/logo.png')" style="margin-bottom:40px;" class="mx-auto text-center">
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="dashboard">
                    <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Dashboard</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap"  :class="{active: modalState == 'main'}" @click="exitModal">
                    <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Profile</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="transactions">
                    <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Transactions</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
                    <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Change Pin</h4>
                </div>
                <div style="position:relative;">
                    <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'privateKey'}" @click="privateKey">
                        <img :src="require('../assets/privatekey.png')" alt="" class="sidebar-link-img">
                        <h4 class="sidebar-link-text">Get Private Key</h4>
                        <img :src="require('../assets/donw white.png')" alt="" class="ml-2 arrow-down">
                    </div>
                    <div class="key-choices" v-if="modalState == 'privateKey'">
                        <div class="choice" @click="viewPrivateKey">
                            <h4>View</h4>
                        </div>
                        <div class="choice" @click="importPrivateKey">
                            <h4>Import</h4>
                        </div>
                    </div>
                </div>
                <div class="sidebar-link d-flex flex-wrap">
                    <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Support</h4>
                </div>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-center align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <img :src="require('../assets/settings.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <div class="profile my-auto">
                            <h5 class="profile-name">{{ init.profile.fullName }}</h5>
                            <h6 class="profile-email">{{ init.profile.emailAddress }}</h6>
                        </div>
                    </div>
                </div>
                <div class="profile-wrapper">
                    <div class="profile text-white">
                        <div class="align-items-end justify-content-end text-right text-white">
                            <button class="btn btn-save" v-if="!isSaved" @click="saveProfile">{{ isSaving ? 'SAVING...' : 'SAVE PROFILE'}}</button>
                            <button class="btn btn-saved" disabled v-if="isSaved">SAVED</button>
                        </div>
                        <div class="alert alert-danger mt-3" v-if="errors.profile">
                            {{ errors.profile }}
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between name">
                            <div class="form-group">
                                <label for="firstName">First Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.firstName">
                            </div>
                            <div class="form-group">
                                <label for="firstName">Last Name</label>
                                <input type="text" id="firstName" class="form-control" v-model="init.profile.lastName">
                            </div>
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between mt-3 bday-number">
                            <div class="form-group">
                                <label for="firstName" style="display:block;">Birthday (YYYY-MM-DD)</label>
                                <DatePicker v-model="init.profile.birthYear" :placeholder="init.profile.birthYear.toString()" type="year" format="YYYY" valueType="format"></DatePicker>
                                <DatePicker v-model="init.profile.birthMm" :placeholder="init.profile.birthMm.toString()" type="month" format="MM" valueType="format"></DatePicker>
                                <DatePicker v-model="init.profile.birthDd" :placeholder="init.profile.birthDd.toString()" type="week" format="DD" valueType="format"></DatePicker>
                                <!-- <DatePicker v-model="init.profile.birthDate" token="MM/DD/YY" valueType="MM/DD/YYYY"></DatePicker> -->
                            </div>
                            <div class="form-group">
                                <label for="firstName">Mobile Number</label>
                                <div class="input-group">
                                    <input type="text" id="firstName" class="form-control" v-model="init.profile.mobilePhone">
                                    <div class="input-group-append">
                                        <button class="btn btn-save" @click="updateMobile">SAVE MOBILE</button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="firstName">Email</label>
                                <div class="input-group">
                                    <input type="text" id="firstName" class="form-control" v-model="init.profile.emailAddress">
                                    <div class="input-group-append">
                                        <button class="btn btn-save"  @click="updateEmail">SAVE EMAIL</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile text-white mt-5">
                        <div class="align-items-end justify-content-end text-right text-white">
                            <button class="btn btn-save" @click="onUpload">UPLOAD ID</button>
                        </div>
                        <div class="alert alert-danger mt-3" v-if="errors.file">
                            {{ errors.file }}
                        </div>
                        <div action="" class="d-flex flex-wrap justify-content-between mt-3 id">
                            <div class="form-group">
                                <label for="firstName">Government ID</label>
                                <b-form-file
                                v-model="config.file"
                                :state="Boolean(config.file)"
                                :placeholder="$t('createAsset.filePlaceholder')"
                                :drop-placeholder="$t('createAsset.dropPlaceholder')"
                                ></b-form-file>
                            </div>
                            <div class="form-group">
                                <label for="firstName">ID Number</label>
                                <input type="text" id="firstName" class="form-control" v-model="config.id">
                            </div>
                        </div>
                        <div action="" class="input-group mt-3 text-white gov-id" v-for="(poid, index) in poids" :key="poid.id">
                            <div class="input-group-prepend">
                                <label for="firstName" style="display:block;" v-if="index == 0">Government ID</label>
                                <input type="text" class="form-control text-white" v-model="poid.poidFile" disabled>
                            </div>
                            <div class="form-group">
                                <label for="firstName" v-if="index == 0">ID Number</label>
                                <input type="text" class="form-control text-white" v-model="poid.entityId">
                            </div>
                            <div class="input-group-append status">
                                <label for="firstName" v-if="index == 0">Status</label>
                                <span class="input-group-text">{{ poid.approvalStatus }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PrivateKey />
        <ImportPrivateKey />
        <ChangePin @close="exitModal" />
        <ConfirmLogout />
    </div>
</template>

<script>
import PrivateKey from '@/components/modal/PINPrivateKey.vue'
import ImportPrivateKey from '@/components/modal/ImportPrivateKey.vue'
import ChangePin from '@/components/modal/ChangePin.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    components: {
        PrivateKey,
        ImportPrivateKey,
        ChangePin,
        ConfirmLogout,
        DatePicker
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
            hasMobile: false,
            isSaved: false,
            isSaving: false,
            poids: {

            },
            config: {
                file: null,
                id: ''
            },
            errors: {
                file: '',
                profile: ''
            }
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },
        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        dashboard() {
            this.$router.push('/')
        },
        transactions() {
            this.$router.push('/transactions')
        },
        getPoids() {
            const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/poids',
            };
            this.axios(options)
                .then((response) => {
                    // Success Code goes here
                    this.poids = response.data

                })
                .catch((error) => {
                    
                });
        },
        saveProfile: function() {
            this.isSaving = true
            const params = {
                birthDd: this.init.profile.birthDd,
                birthMm: this.init.profile.birthMm,
                birthYear: this.init.profile.birthYear,
                firstName: this.init.profile.firstName,
                homePhone: '-',
                lastName: this.init.profile.lastName,
                employer: '-',
                homePhone: '-',
                identityNumber: '-',
                maritalStatus: '-',
                middleName: '-',
                mothersMaidenName: '-',
                nationality: 'PH',
                natureOfWork: '-',
                occupation: '-',
                'permanentAddress.addressExtraInfo': '-',
                'permanentAddress.cityTown': '-',
                'permanentAddress.country': '-',
                'permanentAddress.numberStreet': '-',
                'permanentAddress.postalCode': '-',
                'permanentAddress.provState': '-',
                placeOfBirth: '-',
                'presentAddress.addressExtraInfo': '-',
                'presentAddress.cityTown': '-',
                'presentAddress.country': '-',
                'presentAddress.numberStreet': '-',
                'presentAddress.postalCode': '-',
                'presentAddress.provState': '-',
                sourceOfFunds: '-',
                title: '-'

            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/profile/updatebasic',
            };

            this.axios(options)
                .then(() => { 
                    this.isSaved = true
                    this.getProfile();
                }).catch((err) => {
                    this.isSaving = false
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateEmail: function() {
    
            const params = {
                emailAddress: this.init.profile.emailAddress,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updateemail',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        updateMobile: function() {
    
            const params = {
                mobilePhone: this.init.profile.mobilePhone,
            }

            const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');


            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/updatemobile',
            };

            this.axios(options)
                .then(() => { 
                    this.getProfile();
                }).catch((err) => {
                    this.errors.profile = err.response.data.fieldErrors[0].defaultMessage
                    setTimeout(() => {
                        this.errors.profile = ""
                    }, 5000)
                })

        },
        onUpload: function(){
            let hasError = false;
                if(this.config.file == '' || this.config.file == undefined || this.config.file == null) {
                    this.errors.file = 'File is required.';
                    hasError = true;
                }  

                if(this.config.id == '' || this.config.id == undefined || this.config.id == null) {
                    this.errors.file = 'ID is required.';
                    hasError = true;
                }  

                if(!hasError) {
                    this.showError = false;
                    let formData = new FormData();
                    formData.append('poidType', "ACRICR"); 
                    formData.append('poidDescription', "ACRICR");
                    formData.append('poidNumber', this.config.id);
                    formData.append('poidImageFile', this.config.file);

                    this.axios.post('/cx/uploadpoid',
                                    formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => { 

                    this.showError = true;
                        this.systemMsg = "Config uploaded successfully";
                        this.config.file = null;  
                        this.$emit("close")
                        this.$emit("done")
                        
                    }).catch((err) => {
            
            
                        if(err) {
                        this.showError = true;
                        this.systemMsg = err.response.data.msgText;
                        setTimeout(() => {
                            this.errors.file = ""
                        }, 5000)
                        } 

                    })
                }

        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/#/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/#/setup-profile";
                },1000)
                    
                }  

                })
        },
        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                this.getPoids();
                this.init.profile = response.data;
                if(this.init.profile.mobileNumber == null) {
                    this.hasMobile = false
                } else {
                    this.hasMobile = true
                }


                    
                })
        },
    },
    mounted() {
        this.checkSession();
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100%;
            z-index:2;
            .sidebar-link {
                padding:20px;
                cursor:pointer;
                &.active {
                    background-image: linear-gradient(to right, rgba(202,0,1,1), rgba(188,0,2,0.5), rgba(170,0,0,0.1));
                    border-radius:5px;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 100px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                width:35em;
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .profile-wrapper {
                padding:50px 70px;
                .profile {
                    width:100%;
                    background-image: linear-gradient(to right, #110737, #150754);
                    padding:30px;
                    border-radius:10px;
                    .btn-save {
                        background-color: #ad0d1b !important;
                        color: #FFF !important;
                    }
                    .btn-saved {
                        background-color: #12ad0d !important;
                        color: #FFF !important;
                    }
                    .profile-img {
                        width:8em;
                        image-rendering:optimizeQuality;
                    }
                    .name {
                        .form-group {
                            width:45%;
                            .form-control {
                                border-radius:10px;
                            }
                        }
                    }
                    .bday-number {
                        .form-group {
                            width:30%;
                            .form-control {
                                border-radius:10px;
                            }
                            .mx-datepicker {
                                width:30%;
                            }
                        }
                    }
                    .address {
                        .form-group {
                            width:45%;
                            .form-control {
                                border-radius:10px;
                            }
                        }
                    }
                    .address-2 {
                        .form-group {
                            width:30%;
                            .form-control {
                                border-radius:10px;
                            }
                        }
                    }
                    .id {
                        .form-group {
                            width:45%;
                            .form-control {
                                border-radius:10px;
                            }
                        }
                    }
                    .btn-main {
                        border-radius:10px;
                    }
                    .gov-id {
                        .input-group-prepend {
                            display:block;
                            width:45%;
                            input {
                                background:none;
                            }
                        }
                        .form-group {
                            width:35%;
                        }
                        input {
                            border:1px #0567d7 solid;
                            background:none;
                        }
                        
                        .input-group-append {
                            width:20%;
                            display:block;
                            height:calc(1.5em + 0.75rem + 2px);
                            .input-group-text {
                                width:100%;
                                background:none;
                                border:1px #0567d7 solid;
                                color:white;
                                font-family:SSN-Light;
                                text-align:center;
                            }
                        }
                    }
                }
            }
        }
    }
</style>